<template>
  <div class="page-services">
    <section class="intro">
      <div class="content">
        <b-row>
          <b-col cols="0" md="4"></b-col>
          <b-col cols="0" md="8">
            <h1>{{ $t("pageServices.title") }}</h1>
            <p>{{ $t("pageServices.description") }}</p>
          </b-col>
        </b-row>
      </div>
    </section>

    <section class="services-banner">
      <div class="content">
        <div class="deco-block"></div>
        <b-row>
          <b-col class>
            <img src="@/assets/img/services/Shop-m.gif" alt="services" />
          </b-col>
          <b-col class="d-none d-md-block">
            <!-- <span class="cwu">Chart with us about your project needs</span> -->
          </b-col>
        </b-row>
      </div>
    </section>

    <section class="services-form">
      <div class="content">
        <b-row>
          <b-col cols="0" md="4"></b-col>
          <b-col cols="0" md="8">
            <ServicesFrom />
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import ServicesFrom from '@/components/ServicesForm'
export default {
  metaInfo: {
    title: 'Services'
  },
  name: 'Services',
  components: {
    ServicesFrom
  }
}
</script>

<style lang="scss">
.page-services {
  margin-top: 150px;

  .intro {}

  .services-banner {
    position: relative;
    margin-bottom: 80px;

    .content {
      position: relative;
      max-width: 1440px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 100vw;
        height: 1px;
        background: #fff;
        left: 15px;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        left: calc(8% + 5px);
        width: 1px;
        height: 120%;
        top: 0;
        background: #fff;
      }
    }

    .deco-block {
      position: absolute;
      width: calc(7vw + 10px);
      height: calc(7vw + 12px);
      background: #ccc;
      max-width: 120px;
      max-height: 120px;
      bottom: calc(-7vw - 12px);
      left: 0;

      &::before {
        content: "";
        position: absolute;
        width: 350px;
        height: 1px;
        top: 0;
        left: -50px;
        background: #fff;
      }
    }

    .col:first-child {
      max-width: 90%;
      padding-left: 8%;
    }

    .col {
      position: relative;

      .cwu {
        position: absolute;
        bottom: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .services-banner {
      margin-bottom: -1rem;

      .content {
        &::after {
          height: 200%;
        }
      }

      .deco-block {
        width: calc(8vw + 5px);
      }

      .col:first-child {
        max-width: 35%;
      }
    }
  }

  @media (min-width: 1545px) {
    .services-banner {
      .deco-block {
        bottom: -120px;
      }

      .content {
        &::before {
          left: 35px;
        }
      }
    }
  }
}
</style>
