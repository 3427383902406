<template>
  <form ref="servicesForm" @submit.prevent="submit">
    <!-- <p>Chart with us about your project needs</p> -->
    <p>{{ $t("pageServices.formTitle") }}</p>

    <b-row>
      <b-col cols="12" md="6" v-for="(col, c) in serv" :key="c">
        <div class="form-check" v-for="{ cat, id, options } in col" :key="id">
          <div class="category">
            <!-- <input
              type="checkbox"
              :name="`${toCamal(cat)}-cat`"
              :value="cat"
              class="form-check-input"
              v-model="selectedItem.find((el) => el.id === id).data.length"
              :id="cat"
              hidden
            />-->

            <h3 class="text-uppercase">{{ cat }}</h3>
          </div>

          <div class="options" v-for="(item2, j) in options" :key="`${id}-${j}`">
            <label class="form-check-label" :for="`${id}-${j}`">
              <input type="checkbox" hidden :name="`${cat}[]`" :value="item2" class="form-check-input"
                :id="`${id}-${j}`" />
              <span class="checkmark">{{ item2 }}</span>
            </label>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="service-contact d-flex flex-column">
      <div class="field-group mb-3">
        <label style="min-width: 80px">
          {{
              $t("servicesContactForm.name")
          }}*
        </label>
        <div class="input-wrapper">
          <input name="name" type="text" required />
        </div>
      </div>

      <div class="field-group mb-3">
        <label style="min-width: 80px">
          {{
              $t("servicesContactForm.phone")
          }}*
        </label>
        <div class="input-wrapper">
          <input name="phone" type="tel" required />
        </div>
      </div>

      <div class="field-group mb-3">
        <label style="min-width: 80px">
          {{
              $t("servicesContactForm.email")
          }}*
        </label>

        <div class="input-wrapper">
          <input name="email" type="email" required />
        </div>
      </div>
    </div>
    <div class="submit-wrapper">
      <Loader v-if="submitting" />
      <button v-if="!submitting" type="submit" class="btn btn-primary">{{ $t("global.send") }}</button>
    </div>

    <ThankYouModal />

  </form>
</template>

<script>
// import { observable } from 'vue'

import axios from 'axios'
// import { mapGetters } from 'vuex'
// import { uuid } from 'vue-uuid'
export default {
  name: 'ServicesForm',
  data() {
    return {
      selectedItem: [],
      phoneNum: '',
      // uuid: uuid.v4()
      errors: [],
      submitting: false
    }
  },
  computed: {
    // uuid () {
    //   return uuid.v4()
    // },

    serv() {
      const Obj = {
        col1: [],
        col2: []
      }

      this.$t('services').forEach((el, i) => {
        el.id = this.$uuid.v4()
        this.selectedItem.push(
          {
            id: el.id,
            cat: el.cat,
            data: []
          }
        )
        i < 3 ? Obj.col1.push(el) : Obj.col2.push(el)
      })

      return Obj
    }
  },
  components: {
    ThankYouModal: () => import('./ThankYouModal'),
    Loader: () => import('./Loader.vue')
  },
  methods: {
    // selectedCat (id) {
    //   if (this.selectedItem.find(el => el.id === id).data.length) return true
    //   return false
    // },
    submit(ev) {
      const formData = new FormData(ev.target)
      const url =
        'https://api.irix-design.com/api/serviceform/v1'
      try {
        this.submitting = true
        axios.post(url, formData).then(res => {
          console.log(res)
          this.$root.$emit('bv::show::modal', 'service-ty-modal')
          this.submitting = false
        })
      } catch (error) {
        this.submitting = false
        this.errors.push(error)
        console.log(this.errors)
      }

      //   const req = new Request(
      //     url,
      //     {
      //       body: formData,
      //       method: 'POST'
      //     }
      //   )

      //   fetch(req)
      //     .then(res => res.json())
      //     .then(data => {
      //       console.log(data)
      //     })
    },
    toCamal(str) {
      return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase()
        })
        .replace(/\s+/g, '')
        .replace('/', '')
    }
  },
  mounted() {
    // const reactiveVariable = observable(this.selectedItem)
    // console.log(reactiveVariable)
  }
}
</script>

<style lang="scss">
.form-check {
  padding-left: 37px;
  margin-bottom: 50px;

  .category {
    transform: translateX(-37px);

    h3 {
      font-size: 1rem;
    }
  }

  .checkmark {}
}

.form-check-label {
  position: relative;

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    left: -35px;
    position: absolute;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: -28px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.service-contact {

  input,
  select {
    background: #999;
    border: 1px solid #fff;
    margin-right: 5px;
    height: 30px;
    // width: 100%;
  }

  .input-wrapper {
    display: block;
  }

  input[name="email"] {
    width: 100%;
  }

  input[name="phone"],
  input[name="name"] {
    width: 100%;
    margin: 0;
  }

  input[name="intCode"] {
    width: 10%;
  }

  select[name="phoneCode"] {
    width: 17%;
  }
}

.submit-wrapper {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-left: 0;
  }
}

.services-form .btn-primary {
  width: 100%;
  border: 0;
  height: 30px;
  background: #666;
  padding: 0;
}

@media (min-width: 768px) {
  .service-contact {

    input,
    select {
      background: #999;
      border: 1px solid #fff;
      margin-right: 5px;
      height: 30px;
      // width: 100%;
    }

    .input-wrapper {
      display: inline-block;
      width: 400px;
    }

    input[name="email"] {
      width: 100%;
    }

    // input[name="phone"] {
    //   width: calc(73% + 2px);
    //   margin: 0;
    // }

    input[name="intCode"] {
      width: 10%;
    }

    select[name="phoneCode"] {
      width: 14%;
    }
  }

  .submit-wrapper {
    margin-left: 80px;
  }
}
</style>
